.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

/*
@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}
*/

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.logo {
	background-image: url('data:image/svg+xml,<svg width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.00616695 79.4656C0.525283 37.1702 33.7387 2.73035 75.5906 0.154562V-6.10352e-05H944.378V0.0223481C980.347 0.861728 1010.49 25.2101 1019.98 58.2679L1019.99 58.2653L1020.96 61.867L1020.84 61.898L1020.85 61.9156L985.486 72.0359C976.179 75.1925 965.293 78.5858 953.832 81.8069C749.019 140.581 581.362 225.125 465.814 328.206L439.08 351.715L400.111 354.428C281.392 362.566 155.876 403.708 28.547 476.497L1.00787 492.452V492.888H0V79.4656H0.00616695Z" fill="%23feb70f"/><path d="M1023.9 947.552C1021.93 987.497 990.776 1019.78 951.272 1023.62L950.66 1024H74.3128L73.8651 1023.72C33.1597 1020.35 1.03892 986.793 0.0246986 945.54H0V535.136H0.00782788L0 533.915L28.547 517.187C122.797 462.482 190.766 433.095 275.954 410.942C325.798 398.282 394.22 386.528 397.845 390.597C398.752 391.501 388.33 408.229 374.283 427.217C324.892 496.39 296.345 561.494 285.923 630.666C265.532 765.846 351.626 845.869 497.533 827.785C624.862 812.413 750.831 734.651 789.347 648.298C804.3 614.842 803.847 566.919 787.535 531.654C757.175 466.099 652.503 394.214 551.909 370.252C544.236 368.409 537.399 366.827 531.365 365.432C507.312 359.868 495.992 357.25 495.175 352.738C494.449 348.727 502.028 343.218 516.344 332.813C520.535 329.766 525.304 326.3 530.612 322.328C645.253 236.428 787.081 167.707 956.551 115.715C977.592 109.384 997.894 103.789 1008.93 101.104L1008.88 101.092L1024 97.5717V947.552H1023.9Z" fill="%23feb70f"/><path d="M429.564 416.367C374.736 478.758 334.861 561.494 322.173 638.804C313.564 693.961 329.423 743.693 363.861 768.559C440.892 824.62 617.159 785.739 712.315 691.701C783.456 621.624 782.097 561.041 708.237 492.773C655.221 443.041 555.08 399.639 474.877 391.953C466.169 391.048 460.947 390.505 456.304 391.892C449.35 393.968 443.693 400.372 429.564 416.367Z" fill="%23feb70f"/></svg>');
	width: 45px;
	height: 45px;
	background-repeat: no-repeat;
	background-size: cover;
}

.result-block-container .result-block {
  opacity: 1;
}
